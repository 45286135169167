<template>
  <div class="secure-report container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.components['report-parameters'].tcIOCNationalConferences }}</h1>
    </header>
    <section class="container bg-block">
      <h3>{{ reportName }}</h3>
      <b-form-group class="form-element">
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcSortBy }}</strong>
        </div>
        <b-form-select v-model="report.key" :plain="true" @change="sortChange($event)">
          <option selected="selected" value="">{{ translations.components['report-parameters'].tcDate }}</option>
          <option Value="Location">{{ translations.components['report-parameters'].tcLocation }}</option>
        </b-form-select>
      </b-form-group>
      <b-form-group class="form-element" v-if="this.report.key === 'Location'">
        <div class="form_label bold">
          <strong>{{ translations.components['report-parameters'].tcRegion }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="regions" v-model="region.org_key" :plain="true"
          @change="regionChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element" v-if="this.report.key === 'Location'">
        <div class="form_label bold">
          <strong>{{ translations.components['report-parameters'].tcCountry }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="countries" v-model="country.org_key" :plain="true"
          @change="countryChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(ReportDetail, index) in this.reportUrls" :key="`g${index}`">
          <li>
            <b-link target="_blank" :href="ReportDetail.value"><strong>{{ ReportDetail.text }}</strong></b-link>
          </li>
          <span class="rpt-tab">{{ ReportDetail.ContactInfo }}</span>
        </div>
      </b-form-group>
      <b-form-group>
        <b-button class="btn btn-tertiary btn-w-med ml-4" size="sm" id="btnCancelForm" @click="handleCancelClick">{{
        translations.components['report-parameters'].tcCancel }}</b-button>
      </b-form-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import { translationMixin } from '../../mixins/translationMixin'

export default {
  name: 'ioc-national-conferences',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'report-parameters': []
        }
      },
      title: 'ICC National Conferences',
      values: {},
      reportName: '',
      reportParamParentKey: '',
      regions: [],
      countries: [],
      reportUrls: null,
      report: {
        key: null,
        value: null,
      },
      region: {
        org_key: null,
        org_name: null,
      },
      country: {
        org_key: null,
        org_name: null,
      },
      reportUrls: null,
      reportCountryData: {
        ind_key: null,
        org_key: null,
      },
      reportUrlsData: {
        ind_key: null,
        org_key: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getRegions: 'secureReport/getRegions',
      getCountries: 'secureReport/getCountries',
      getNationalUrls: 'secureReport/getNationalUrls',
      getRegionUrls: 'secureReport/getRegionUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      this.report.key = ''
      this.region.org_key = constantData.empty_guid
      this.country.org_key = constantData.empty_guid
      this.setLoadingStatus(false)
    },
    async sortChange(evt) {
      this.setLoadingStatus(true)
      await this.getRegions()
      this.regions = this.regionDetails.map((item) => {
        return { text: item.org_name, value: item.org_key }
      })
      this.regions.unshift({ text: this.translations.components['report-parameters'].tcSelectARegion, value: constantData.empty_guid })
      if (this.countries.length === 0){
        this.countries.unshift({ text: this.translations.components['report-parameters'].tcSelectACountry, value: constantData.empty_guid })
      }
      this.region.org_key = constantData.empty_guid
      this.country.org_key = constantData.empty_guid
      this.reportUrls = null
      this.setLoadingStatus(false)
    },
    async regionChange(evt) {
      this.setLoadingStatus(true)
      this.reportCountryData.ind_key = this.userId
      this.reportCountryData.org_key = this.region.org_key
      await this.getCountries(this.reportCountryData)
      this.countries = this.countryDetails.map((item) => {
        return { text: item.t_org_name, value: item.t_org_key }
      })
      this.countries.unshift({ text: this.translations.components['report-parameters'].tcSelectACountry, value: constantData.empty_guid })
      this.country.org_key = constantData.empty_guid
      this.reportUrls = null
      this.setLoadingStatus(false)
    },
    async countryChange(evt) {
      this.setLoadingStatus(true)
      this.reportUrlsData.ind_key = this.userId
      this.reportUrlsData.org_key = this.country.org_key
      await this.getNationalUrls(this.reportUrlsData)
      this.reportUrls = this.nationalUrlDetails.map((item) => {
        return { text: item.Name, value: item.URL, ContactInfo: item.ContactInfo }
      })
       this.setLoadingStatus(false)
    },
    handleCancelClick() {
      this.$router.push({ path: `/reports/report-center` })
    },
  },

  async created() {
     await this.getPseudoComponentTranslations('report-parameters').then((results) => {
      const pseudoComponentTranslations = results
      this.$set(this.translations, 'components', pseudoComponentTranslations)
    })
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      regionDetails: 'secureReport/regions',
      countryDetails: 'secureReport/countries',
      nationalUrlDetails: 'secureReport/nationalUrls',
      regionYearDetails: 'secureReport/regionReportYears',
      reportParentKey: 'reportParameter/reportParentKey',
      selectedReportKey: 'reportParameter/selectedReportKey',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
    }),
  },
  components: {},
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 300px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox+div {
  display: inline-block;
}

.rpt-tab {
  padding-left: 4em;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}
</style>
